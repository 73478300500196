@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;900&display=swap');

html,
body {
    background: #14141f;
}

:root {
    color: #DEDEE3;
}

.ant-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    padding: 16px 14px;
    box-shadow: none;
    animation: none !important;
    font-weight: 700;
}

.ant-btn-default {
    border: 1px solid #6320e5;
    background-color: transparent;
}

.ant-btn-default:not(:disabled):hover {
    border: 1px solid #6320e5;
    background: #2B2B36;
    /* background: linear-gradient(#2B2B36, #2B2B36) padding-box padding-box,
      linear-gradient(52deg, #7D50FF 0%, #7785FF 100%) border-box border-box; */
}

.ant-btn-primary {
    color: #FBFBFF;
    border: none;
    background: linear-gradient(75.86deg, #6320E6 6.84%, #7B59FD 99.53%);
}

.ant-btn-primary:hover {
    background: linear-gradient(75.86deg, #7D50FF 6.84%, #7785FF 107.11%);
}

.ant-btn-primary:active {
    background: linear-gradient(75.86deg, #4603CA 6.84%, #742EFD 99.53%) !important;
}

.ant-btn-primary:disabled {
    color: #FBFBFF;
    background: #2B2B36;
}

.CancelButton {
    color: #FBFBFF;

    border: 1px solid transparent;
    background: linear-gradient(#20202a, #20202a) padding-box padding-box, linear-gradient(75.86deg, #6320E6 6.84%, #7B59FD 99.53%) border-box border-box;
    border-radius: 8px;
}


.CancelButton:not(:disabled):not(.ant-btn-disabled):hover {
    color: #FBFBFF;

    border: 1px solid transparent;
    background: linear-gradient(#2B2B36, #2B2B36) padding-box padding-box, linear-gradient(52deg, #7D50FF 0%, #7785FF 100%) border-box border-box;
    border-radius: 8px;
}

.CancelButton:not(:disabled):not(.ant-btn-disabled):active {
    color: #FBFBFF;

    border: 1px solid transparent;
    background: linear-gradient(#18181F, #18181F) padding-box padding-box, linear-gradient(52deg, #4603CA 0%, #742EFD 92.44%) border-box border-box;
    border-radius: 8px;
}

.ant-btn-default:disabled {
    border: none;
}

.ant-btn:not(:disabled):focus-visible {
    outline: none !important;
    outline-offset: 0 !important;
    transition: none !important;
}


/* Breadcrumb  */
.ant-breadcrumb {
    font-family: Inter, sans-serif;
}

/* Modal */
.ant-modal {
    font-family: Inter, sans-serif;
}

.ant-modal .ant-modal-title {
    margin-bottom: 24px;
    font-weight: 700;
    color: #FBFBFF;
    background: transparent;
}

.ant-modal-header {
    background-color: transparent !important;
}

.ant-modal .ant-modal-content {
    background: linear-gradient(80.47deg, #18181F 6.62%, #2D2D3A 148.62%);
}

.ant-modal .ant-modal-close {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    top: 45px;
    right: 40px;
    color: #F3F3F8
}

.ant-modal-close-x {
    display: flex !important;
}

.ant-modal-body > span {
    font-size: 16px;
    font-weight: 400;
    color: #FBFBFF;
}

.ant-modal-body > form > label {
    font-size: 16px;
    font-weight: 400;
    color: #FBFBFF;
}

.ant-modal-footer {
    display: flex;
    justify-content: center;
    gap: 24px;
    flex-direction: row-reverse;
    margin-top: 32px !important;
}

/* Input */
.ant-input {
    height: 40px;
    border-radius: 8px;
    background: transparent;
    border-color: #474755;
    color: #FBFBFF;
}

.ant-input:hover {
    border-color: #F3F3F8;
}

.ant-input:active {
    border-color: #F3F3F8;
}

.ant-input:focus {
    border-color: #F3F3F8;
}

.ant-input-password {
    padding: 0 11px;
    border-radius: 8px;
    background: transparent !important;
    border-color: #474755;
    color: #FBFBFF;
}

.ant-input-password:hover {
    border-color: #F3F3F8;
}

.ant-input-password:focus {
    border-color: #F3F3F8 !important;
    box-shadow: none;
}

.ant-input-affix-wrapper-focused {
    border: 1px solid #F3F3F8 !important;
    box-shadow: none;
}

.ant-input-password:active {
    border-color: #F3F3F8 !important;
    box-shadow: none;
}

.ant-form-item {
    margin-bottom: 0;
}

.ant-form-item .ant-form-item-explain-error {
    font-size: 12px;
    font-weight: 400;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.stepNumber {
    color: #767687;
}

/* Radio Button*/
.ant-radio-button-wrapper:first-child {
    margin-right: 8px;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    box-shadow: inset 5px 5px 13px #202124;
}

:where(.css-dev-only-do-not-override-1je3gqh).ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-selected {
    background-color: #F0F0F0 !important;
}

#root {
    background: rgb(20, 20, 31);
}

/*!* Notification *!*/
.ant-notification-notice {
    background-color: #2b2b36;
    padding: 16px !important;
}

.ant-notification-notice .ant-notification-notice-with-icon .ant-notification-notice-message {
    font-weight: 700;
    line-height: 19.36px;
    color: #FBFBFF;
    margin-inline-start: 32px !important;
    padding-top: 4px;
}

.ant-notification-notice-description {
    color: #FBFBFF;
    margin-inline-start: 32px !important;
    width: 100%;
    max-width: 272px;
}

.ant-notification-notice .ant-notification-notice-close {
    right: 16px;
    top: 16px;
    width: 24px;
    height: 24px;
    color: #FBFBFF;
}

.ant-notification-notice .ant-notification-notice-close:hover {
    background-color: transparent;
    color: #FBFBFF;
}

@media (max-width: 719px) {
    .ant-picker-panel:last-child .ant-picker-date-panel {
        display: none !important;
    }

    .ant-picker-panel:first-child
    .ant-picker-date-panel
    .ant-picker-header-next-btn,
    .ant-picker-panel:first-child
    .ant-picker-date-panel
    .ant-picker-header-super-next-btn {
        visibility: initial !important;
    }

    .ant-picker-range-wrapper {
        min-width: 288px !important;
    }

    .ant-picker-presets {
        display: none !important;
    }

    .ant-picker-panel-container {
        margin-left: 0 !important;
    }

    .ant-notification {
        top: 72px !important;
        bottom: auto;
        width: 100%;
        margin-inline-end: 0px;
    }

    .ant-notification-notice {
        width: 100%;
        max-width: 100%;
        margin-bottom: 16px;
        border-radius: 0px;
    }
}

/*!* End Notification *!*/

/* Anchor antd*/

.ant-affix {
    top: 72px !important;
}

/* Global variables */
:root {
    --font-inter: 'Inter', sans-serif;
}
